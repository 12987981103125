import React from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import Switcher from '../components/switcher';
import { Link } from 'react-router-dom';
import {LuClock, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdOutlineArrowForward, LuCalendarDays} from "../assets/icons/vander"
import { blogData, vacation } from '../data/data';
import { CheckCircle } from 'feather-icons-react/build/IconComponents';

export default function Vacation() {
    return (
        <>
            <section className="relative md:py-24 py-16">
                <div className="container">
                <h2 to="#" className="font-semibold transition duration-500" style={{fontSize:'30px',marginBottom:'35px',marginTop:'-25px'}}>Foundation / Skill Development / Vacation Courses</h2>
                    <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-[30px]">
                        {
                            vacation.map((ele, index) => (
                                <div key={index} className="group relative overflow-hidden bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 hover:shadow-md transition-all duration-500">
                                    <div style={{maxWidth:'100%',height:'fit-content',backgroundColor:'#1e293b'}}>
                                        <div className="relative p-3" style={{display:'flex',alignItems:'center',justifyContent:'center',textAlign:'center'}}>
                                            <Link to={`#`} className="title text-lg font-medium hover:text-violet-600 duration-500 ease-in-out">{ele.title}</Link>                             
                                        </div>
                                    </div>

                                    <div className="relative p-6">

                                        <div className="">
                                            <div className="flex mb-4" style={{display:'flex',flexDirection:'column'}}>
                                                <span className="text-slate-400 text-[16px] inline-flex mb-4"><CheckCircle className="text-slate-900 dark:text-white me-2" />Target Group : {ele.p1}</span>
                                                <span className="text-slate-400 text-[16px] inline-flex mb-4"><CheckCircle className="text-slate-900 dark:text-white me-2"/>Duration : {ele.p2}</span>
                                                <span className="text-slate-400 text-[16px] inline-flex mb-4"><CheckCircle className="text-slate-900 dark:text-white me-2"/>Timing : {ele.p3}</span>
                                                <span className="text-slate-400 text-[16px] inline-flex mb-4"><CheckCircle className="text-slate-900 dark:text-white me-2"/>Basic & Advanced levels</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-4" style={{display:'flex',justifyContent:'center',bottom:0}}>
                                    <Link to="#" className="btn bg-transparent hover:bg-violet-600 border-violet-600 text-violet-600 hover:text-white rounded-full ms-2" style={{cursor:'pointer',}}>Learn More</Link>
                                </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section >
        </>
    )
}
