import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { data } from "../../data/data";
import image7 from "../../assets/images/kbs1.png";

export default function KbsHomeChoose() {
  const params = useParams();
  const id = params.id;
  const creater = data.find((creatorr) => creatorr.id === parseInt(id));

  const [activeIndex, setIndex] = useState(0);
  const [placeBid, setPlaceBid] = useState(false);
  const [buyNow, setBuyNow] = useState(false);

  useEffect(() => {
    document.documentElement.classList.add("dark");
  }, []);

  return (
    <>
      

      <section className="relative pt-28 md:pb-24 pb-16" style={{justifyContent:'center',alignContent:'center',alignItems:'center'}}>
        <div className="container">
          <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 gap-[30px]">
           
            <div className="lg:col-span-7 lg:me-8">
            <h5 className="mb-4 md:text-3xl text-2xl md:leading-snug leading-snug font-semibold mt-8">
                {creater?.title
                  ? creater?.title
                  : "WHY CHOOSE US"}
              </h5>
              <p className="text-slate-400 mt-4">
                KBS Academy is a learning centre that provides coaching for
                major entrance examinations like NEET, JEE, KEAM, NATA, ICAR,
                etc. It also offers tuition for classes 8th to 12th. Our courses
                include Tuition Only, Entrance Only, Tuition + Entrance,
                Entrance + Schooling, Bridge Courses & Foundation Courses.
              </p>
              <p className="text-slate-400 mt-4">
                It was started with the objective of providing excellent science
                education for Plus I and Plus II students in Palakkad. The aim
                of the academy is to become an outstanding educational
                institution in the district. The institute now has a hostel
                facility and accommodates students from outside Kerala as well.
              </p>
              <p className="text-slate-400 mt-4">
                Over the years, it has helped hundreds of students pass their
                examinations with flying colors. KBS is the only institution in
                the district providing value-based education and empowering
                students to pursue higher education with a mission and vision.
                In short, KBS is set to ensure a glorious future for the young
                generation.
              </p>
            </div>

            
            <div className="lg:col-span-5">
              <img
                src={image7}
                className="rounded-md shadow dark:shadow-gray-700"
                style={{ width: "372px", height: "372px" }}
                alt="KBS Academy"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
