import React, { useState } from "react";

export default function WhatsappChat() {
  const [isHovered, setIsHovered] = useState(false);
  const phoneNumber = "+919995484636";
  const whatsappUrl = `https://wa.me/${phoneNumber})}`;

  return (
    <div
      className="flex justify-center items-center bg-gray-100 relative"
      style={{ zIndex: 99999 }}
    >
      <a
        href={whatsappUrl}
        target="_blank"
        rel="noopener noreferrer"
        style={{ position: "fixed", bottom: 20, right: 30 }}
      >
        <div
          className="logos--whatsapp-icon"
          style={{
            transition:
              "transform 0.3s ease, opacity 0.3s ease, box-shadow 0.3s ease",
            transform: isHovered ? "scale(1.1)" : "scale(1)",
            opacity: isHovered ? 0.8 : 1,
            boxShadow: isHovered ? "0px 4px 10px rgba(0, 0, 0, 0.2)" : "none",
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        />
      </a>
    </div>
  );
}
