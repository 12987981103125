import React from 'react';
import TinySlider from 'tiny-slider-react';
import '../../../node_modules/tiny-slider/dist/tiny-slider.css';

const testimonialVideoIds = [
  '1XXh34MCF4nhGpIxx2kDumaB8otw1HYPn',
  '1JbOgoJ_D3Fd3jUtDSXxMYBj3a8CTHiQm',
  '1aGb2RC56ly0_Xn014dQ9T3f16wnnCekt',
  '1O_5NOvV0wogFf6MGTg5ENC0oal7zXqBE',
  '1UYQimf5bt8VKjQFPazrzeYLSUqY9W2Lh',
  '12d941JT0-OwsdIDwMTseANT767Jbrelp',
  '1bYIjSuTiIk944-es2ujnpni-jRL_PIRL',
  '14Jbeq8zgYWUxNLjqF1hrMPlDZF4iv1T9',
  '16TczH3Za8AB_djC9PrDF9hMZz7vf6NLm',
  '1TWvFbPheXpEeUOFamM7TG18kpAv1JHhE',
  '1WCzakufDmziLcJYCWrvpKgQyUQ00yZ7E',
  '1ZPnNDSDJi3ZpxBbhYK3j60jgXqwDbVjm',
  '1_FT0G44sdbUuCMQGk4vYNuqdJSAo2PgG',
  '14buzjRuJqQ4j63dgbkg8FMgkTECJMaxU',
  '13ezHZ56h5w2WPydJZ7YOmmZWQK-nL_S2',
  '1lgk12z4WDPHmGThPsTgKyf2dpnsbc4KL',
  '1KqkhY3ISdPWcd88gk8MBQYNWgG_9Irta',
  '1j-EDq1CtgjptNe_pJqbSS32rLsMIBs9N',
  '1RqG0ITsBbznbeXaKJvyXkhwD8jo5g901',
];

const settings = {
  lazyload: true,
  controls: true,
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplay: false,
  autoplayButtonOutput: false,
  autoplayTimeout: 3000,
  navPosition: 'bottom',
  controlsText: [
    '<i class="mdi mdi-chevron-left"></i>',
    '<i class="mdi mdi-chevron-right"></i>',
  ],
  nav: false,
  speed: 400,
  gutter: 0,
  responsive: {
    1025: { items: 4 },
    992: { items: 3 },
    767: { items: 2 },
    320: { items: 1 },
  },
};

export default function HomeTestimonial() {
  return (
    <div className="container mt-12">
      <div className="md:flex justify-between items-center">
        <div className="md:w-10/12 md:text-start text-center">
          <h3 className="md:text-[30px] text-[26px] font-semibold">
            Student Testimonials
          </h3>
        </div>
      </div>

      <div className="grid relative grid-cols-1 mt-10">
        <div className="tiny-four-icon-item">
          <TinySlider settings={settings}>
            {testimonialVideoIds.map((id, index) => (
              <div className="tiny-slide" key={index}>
                <div className="group relative overflow-hidden bg-white dark:bg-slate-900 rounded-lg p-4 shadow dark:shadow-gray-800 ease-in-out duration-500 m-2">
                  <a
                    href={`https://drive.google.com/file/d/${id}/view`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <iframe
                      src={`https://drive.google.com/file/d/${id}/preview`}
                      width="100%"
                      height="300"
                    //   allow="autoplay"
                      allow="autoplay; fullscreen"
                      allowFullScreen
                      style={{
                        borderRadius: '8px',
                        border: 'none',
                      }}
                    ></iframe>
                  </a>
                </div>
              </div>
            ))}
          </TinySlider>
        </div>
      </div>
    </div>
  );
}
