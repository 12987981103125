import React, { useState } from "react";

export default function PhoneCallButton() {
  const [isHovered, setIsHovered] = useState(false);
  const phoneNumber = "+917356888810";

  return (
    <div
      className="flex justify-center items-center bg-gray-100 relative"
      style={{ zIndex: 99999 }}
    >
      <a
        href={`tel:${phoneNumber}`}
        style={{ position: "fixed", bottom: 20, left: 30 }}
      >
        <div
          style={{
            padding: "10px",
            borderRadius: "50%",
            backgroundColor: "green",
            display: 'flex',
            justifyContent: 'center',
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="line-md--phone-call-twotone"
            style={{
              transition:
                "transform 0.3s ease, opacity 0.3s ease, box-shadow 0.3s ease",
              transform: isHovered ? "scale(1.1)" : "scale(1)",
              opacity: isHovered ? 0.8 : 1,
              boxShadow: isHovered ? "0px 4px 10px rgba(0, 0, 0, 0.2)" : "none",
              color: "#cccccc",  // Adjusts color if needed
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          />
        </div>
      </a>
    </div>
  );
}
