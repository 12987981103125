import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Index from './pages/index/index';
import { useEffect } from 'react';
import React from 'react';
import IndexSeven from './pages/index/index-seven';
import Contact from './pages/contact';
import About from './pages/about';
import Creators from './pages/creator/creators';
import CreatorProfile from './pages/creator/creator-profile';
import CreatorProfileEdit from './pages/creator/creator-profile-edit';
import BecomeCreator from './pages/creator/become-creator';
import Blogs from './pages/blog/blogs';
import BlogDetail from './pages/blog/blog-detail';
import Login from './pages/auth/login';
import Signup from './pages/auth/signup';
import ResetPassword from './pages/auth/reset-password';
import Comingsoon from './pages/special/comingsoon';
import Maintenance from './pages/special/maintenance';
import Error from './pages/special/error';
import Thankyou from './pages/special/thankyou';
import Terms from './pages/terms';
import Support from './pages/helpcenter/support';
import Guides from './pages/helpcenter/guides';
import ItemDetail from './pages/explore/item-detail';
import IndexTwo from './pages/index/index-two';
import IndexTen from './pages/index/index-ten';
import ExploreOne from './pages/explore/explore-one';
import IndexThree from './pages/index/index-three';
import IndexFour from './pages/index/index-four';
import IndexFive from './pages/index/index-five';
import IndexSix from './pages/index/index-six';
import IndexEight from './pages/index/index-eight';
import IndexNine from './pages/index/index-nine';
import Auction from './pages/explore/auction';
import Activity from './pages/explore/activity';
import Collections from './pages/explore/collections';
import Wallet from './pages/wallet';
import UploadWork from './pages/explore/upload-work';
import HelpcenterFaqs from './pages/helpcenter/helpcenter-faqs';
import PrivacyPolicy from './pages/privacy-policy';
import LockScreen from './pages/auth/lock-screen';
import HelpcenterOverview from './pages/helpcenter/helpcenter-overview';
import ScrollToTop from './components/scroll-top';
import ExploreTwo from './pages/explore/explore-two';
import ExploreThree from './pages/explore/explore-three';
import KBSHome from './pages/kbs-home/kbs-home';
import KbsManagement from './pages/kbs-home/kbs-management/kbs-management';
import SStudentsFeedback from './pages/kbs-home/students-feedback/students-feedback';
import StudentsFeedback from './pages/kbs-home/students-feedback/students-feedback';
import OurCenters from './pages/kbs-home/our-centers/our-centers';
import ContactUs from './pages/kbs-home/contact-us/contact-us';
import OnlineLiveClasses from './pages/kbs-home/courses/online-live-classes';
import OurFaculty from './pages/kbs-home/our-faculty/our-faculty';
import TuitionEntrance from './pages/kbs-home/tuition-entrance/tuition-entrance';
import TuitionOnly from './pages/kbs-home/tuition-only/tuition-only';
import FoundationSkill from './pages/kbs-home/foundation-skill/foundation-skill';
import OnlineTestSeries from './pages/kbs-home/facilities/online-test-series';
import EntranceOnly from './pages/kbs-home/entrance-only/entrance-only';
import Gallery from './pages/kbs-home/facilities/gallery';
import FeePayment from './pages/kbs-home/fee-payment/fee-payment';
import ImportantDates from './pages/kbs-home/important-dates copy/important-dates';
import Events from './pages/kbs-home/events/events';
import Neet from './pages/kbs-home/neet/neet';
import Jee from './pages/kbs-home/jee/jee';
import Keam from './pages/kbs-home/keam/keam';
import LearningMaterial from './pages/kbs-home/learning-material/learning-material';
import ExamEnquiry from './pages/kbs-home/exam-enquiry/exam-enquiry';
import Result2020 from './pages/kbs-home/results-2020/results-2020';
import Result2021 from './pages/kbs-home/results-2021/results-2021';
import Result2022 from './pages/kbs-home/results-2022/results-2022';
import AnimationSwitcher from './pages/kbs-home/animation-switcher/animation-switcher';
import Result2023 from './pages/kbs-home/results-2023/results-2023';
import Result2024 from './pages/kbs-home/results-2024/results-2024';
import WhatsappChat from './pages/kbs-home/whatsapp-chat/whatsapp-chat';
import PhoneCall from './pages/kbs-home/phone-call/phone-call';

function App() {
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add('dark');
    document.body.classList.add('font-urbanist', 'text-base', 'text-black', 'dark:text-white', 'dark:bg-slate-900');

  });
  return (
    <BrowserRouter>
      <ScrollToTop />
      <WhatsappChat />
      <PhoneCall />
      <Routes>
        {/* <Route path="/" element={<Navigate to="/index" replace />} /> */}

        {/* <Route path="/index" element={<Index />} /> */}
        <Route path="/" element={<KBSHome />} />
        <Route path="/index-seven" element={<IndexSeven />} />
        <Route path="/index-two" element={<IndexTwo />} />
        <Route path="/index-three" element={<IndexThree />} />
        <Route path="/index-ten" element={<IndexTen />} />
        <Route path="/index-nine" element={<IndexNine />} />
        <Route path="/index-eight" element={<IndexEight />} />
        <Route path='/explore-one' element={<ExploreOne />} />
        <Route path='/auction' element={<Auction />} />
        <Route path='/activity' element={<Activity />} />
        <Route path='/collections' element={<Collections />} />
        <Route path='/wallet' element={<Wallet />} />
        <Route path="/explore-two" element={<ExploreTwo />} />
        <Route path="/explore-three" element={<ExploreThree />} />
        <Route path="/index-four" element={<IndexFour />} />
        <Route path='/upload-work' element={<UploadWork />} />
        <Route path="/index-six" element={<IndexSix />} />
        <Route path="/index-five" element={<IndexFive />} />

        <Route path='/item-detail' element={<ItemDetail />} />
        <Route path='/item-detail/:id' element={<ItemDetail />} />
        <Route path='/helpcenter-guides' element={<Guides />} />
        <Route path='/helpcenter-support' element={<Support />} />
        <Route path='/helpcenter-support' element={<Support />} />
        <Route path='/helpcenter-faqs' element={<HelpcenterFaqs />} />
        <Route path='/privacy' element={<PrivacyPolicy />} />
        <Route path='/terms' element={<Terms />} />
        <Route path='/comingsoon' element={<Comingsoon />} />
        <Route path='/maintenance' element={<Maintenance />} />
        <Route path='/error' element={<Error />} />
        <Route path='/thankyou' element={<Thankyou />} />

        <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='/lock-screen' element={<LockScreen />} />
        <Route path='/helpcenter-overview' element={<HelpcenterOverview />} />

        <Route path='/blogs' element={<Blogs />} />
        <Route path='/blog-detail' element={<BlogDetail />} />
        <Route path='/blog-detail/:id' element={<BlogDetail />} />

        <Route path='/become-creator' element={<BecomeCreator />} />
        <Route path='/creator-profile' element={<CreatorProfile />} />
        <Route path='/creator-profile/:id' element={<CreatorProfile />} />
        <Route path='/creator-profile-edit' element={<CreatorProfileEdit />} />
        <Route path='/creators' element={<Creators />} />
        <Route path="/aboutus" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/kbs-management"  element={<KbsManagement />} />
        <Route path="/our-centers"element={<OurCenters />} />
        <Route path="/students-feedback" element={<StudentsFeedback />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/online-live-classes" element={<OnlineLiveClasses/>} />
        <Route path="/our-faculty" element={<OurFaculty/>} />
        <Route path="/tuition-entrance" element={<TuitionEntrance/>} />
        <Route path="/tuition-only" element={<TuitionOnly/>} />
        <Route path="/foundation-skill" element={<FoundationSkill/>} />
        <Route path="/entrance-only" element={<EntranceOnly/>} />
        <Route path="/online-test-series" element={<OnlineTestSeries/>} />
        <Route path="/gallery" element={<Gallery/>} />
        <Route path="/fee-payment" element={<FeePayment/>} />
        <Route path="/important-dates" element={<ImportantDates/>} />
        <Route path="/events" element={<Events/>} />
        <Route path="/neet" element={<Neet/>} />
        <Route path="/jee" element={<Jee/>} />
        <Route path="/keam" element={<Keam/>} />
        <Route path="/learning-material" element={<LearningMaterial/>} />
        <Route path="/exam-enquiry" element={<ExamEnquiry/>} />
        <Route path="/results-2020" element={<Result2020/>} />
        <Route path="/results-2021" element={<Result2021/>} />
        <Route path="/results-2022" element={<Result2022/>} />
        <Route path="/results-2023" element={<Result2023/>} />
        <Route path="/results-2024" element={<Result2024/>} />
        <Route path="/animation-switcher" element={< AnimationSwitcher/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
