import item1 from '../assets/images/items/1.jpg';
import item2 from '../assets/images/items/2.gif';
import item3 from '../assets/images/items/2.jpg';
import item4 from '../assets/images/items/3.jpg';
import item5 from '../assets/images/items/3.gif';
import item6 from '../assets/images/items/4.jpg';
import item7 from '../assets/images/items/5.jpg';
import item8 from '../assets/images/items/6.jpg';
import item9 from '../assets/images/items/8.jpg';
import item10 from '../assets/images/items/4.gif';
import item11 from '../assets/images/items/5.gif';
import item12 from '../assets/images/items/9.jpg';
import item13 from '../assets/images/items/1.gif';
import item14 from '../assets/images/items/20.jpg';
import item15 from '../assets/images/items/7.jpg';
import item16 from '../assets/images/items/10.jpg';
import item17 from '../assets/images/items/11.jpg';
import item18 from '../assets/images/items/12.jpg';
import item19 from '../assets/images/items/16.jpg';
import item22 from '../assets/images/items/14.jpg';
import item23 from '../assets/images/items/7.gif';
import item24 from '../assets/images/items/8.gif';
import item25 from '../assets/images/items/15.jpg';
import item26 from '../assets/images/items/17.jpg';
import item27 from '../assets/images/items/18.jpg';
import item28 from '../assets/images/items/19.jpg';

import image1 from '../assets/images/avatar/1.jpg';
import image2 from '../assets/images/avatar/2.jpg';
import image3 from '../assets/images/items/3.gif';
import image4 from '../assets/images/avatar/3.jpg';
import image5 from '../assets/images/avatar/4.jpg';
import image6 from '../assets/images/avatar/5.jpg';
import image7 from '../assets/images/avatar/6.jpg';
import image8 from '../assets/images/items/5.gif';
import image9 from '../assets/images/avatar/7.jpg';
import image10 from '../assets/images/items/5.gif';
import image11 from '../assets/images/avatar/8.jpg';
import image12 from '../assets/images/items/2.gif';

import blog1 from '../assets/images/blog/01.jpg';
import blog2 from '../assets/images/blog/02.jpg';
import blog3 from '../assets/images/blog/03.jpg';
import blog4 from '../assets/images/blog/04.jpg';
import blog5 from '../assets/images/blog/05.jpg';
import blog6 from '../assets/images/blog/06.jpg';
import blog7 from '../assets/images/blog/07.jpg';
import blog8 from '../assets/images/blog/08.jpg';
import blog9 from '../assets/images/blog/09.jpg';
import blog10 from '../assets/images/blog/10.jpg';
import blog11 from '../assets/images/blog/11.jpg';
import blog12 from '../assets/images/blog/12.jpg';
import KBS from '../assets/images/kbs.png';
import feedback1 from '../assets/images/feedback1.png';
import feedback2 from '../assets/images/feedback2.png'
import feedback3 from '../assets/images/feedback3.png'
import events1 from '../assets/images/events1.jpg'
import events2 from '../assets/images/events2.jpg'
import events3 from '../assets/images/events3.jpg'
import events4 from '../assets/images/events4.jpg'
import Result1 from '../assets/images/result-1.jpg'
import Result2 from '../assets/images/result-2.jpg'
import Result3 from '../assets/images/result-3.jpg'
import Result4 from '../assets/images/result-4.jpeg'
import Result5 from '../assets/images/result-5.jpeg'
import Result6 from '../assets/images/result-6.jpeg'
import Result7 from '../assets/images/result-7.jpg'
import Result8 from '../assets/images/result-8.jpg'
import Result9 from '../assets/images/result-9.jpg'
import Result10 from '../assets/images/result-10.jpg'
import Result11 from '../assets/images/result-11.jpg'
import Result12 from '../assets/images/result-12.jpg'
import Result13 from '../assets/images/result-13.jpg'
import Result14 from '../assets/images/result-14.jpg'
import Result15 from '../assets/images/result-15.jpg'
import Result16 from '../assets/images/result-16.jpg'
import Result17 from '../assets/images/result-17.jpg'
import Result18 from '../assets/images/result-18.jpg'
import Result19 from '../assets/images/result-19.jpg'
import Result20 from '../assets/images/result-20.jpg'
import Result21 from '../assets/images/result-21.jpg'
import Result22 from '../assets/images/result-22.jpg'
import Result23 from '../assets/images/result-23.jpg'
import Result24 from '../assets/images/result-24.jpg'
import Result25 from '../assets/images/result-25.jpg'
import Result26 from '../assets/images/result-26.jpg'
import Result27 from '../assets/images/result-27.jpg'
import Result28 from '../assets/images/result-28.jpg'
import Result29 from '../assets/images/result-29.jpg'
import Result31 from '../assets/images/result-31.jpg'
import Result32 from '../assets/images/result-32.jpeg'
import image21 from '../assets/images/image11 .jpg';
import image22 from '../assets/images/image12 .jpg';
import image23 from '../assets/images/image13 .jpg';
import image24 from '../assets/images/image14.jpg';
import Result33 from '../assets/images/Result-32.jpg';
import Result34 from '../assets/images/Result-33.jpg'
import Result35 from '../assets/images/new-17.jpg'

export const data = [
     {
        id:1,
        image: item1,
        subtext: '15',
        title: 'Genuine Undead #3902',
        avatar: image1,
        icon:'material-symbols--timer',
        iconlocation:'akar-icons--location',
        time:'10:00 am',
        location:'KBS Academy Town Branch & Pirayiri Branch',
        name:"Steven Townsend",
        date: 'December 29, 2023 6:0:0',
        liveDate:'June',
        kbscourse:"11th State & CBSE Tuitions & Entrance New Batch",
        category: 'Games',
        subImage:[
            {
                index:1,
                image:item13,
            },
            {
                index:2,
                image:item3,
            },
            {
                index:3,
                image:item4,
            },
        ]
        },
        {
            id:2,
            image: item2,
            subtext: '15',
            title: 'Windchime #768',
            kbscourse:"Repeater Batch NEET 2025, JEE 2025",
            avatar: image2,
            name:"Tiffany Betancourt",
            liveDate:'June',
             category: 'Games',
            subImage:[
                {
                    index:4,
                    image:item1,
                },
                {
                    index:5,
                    image:image12,
                },
                {
                    index:6,
                    image:item6,
                },
            ]
        },
        {
            id:3,
            image: item3,
            subtext: '15',
            title: 'Probably A Label #3277',
            avatar: image3,
            icon:'material-symbols--timer',
            iconlocation:'akar-icons--location',
            time:'9:00 am to 5:00 pm',
            location:'Palakkad Town Campus',
            kbscourse:"Plus Two Tuition & Entrance Classes (State & CBSE)",
            name:"Jacqueline Burns",
            liveDate:'June',
            category: 'Art',
            subImage:[
                {
                    index:7,
                    image:item14,
                },
                {
                    index:8,
                    image:item13,
                },
                {
                    index:9,
                    image:item5,
                },
            ]
        }, {
            id:4,
            image: item4,
            subtext: '1',
            title: 'Probably A Label #1711',
            avatar: image4,
            icon:'material-symbols--timer',
            iconlocation:'akar-icons--location',
            time:'9:00 am to 5:00 pm',
            location:'Palakkad Town Campus, Pirayiri Ottapalam',
            name:"Mari Harrington",
            kbscourse:"Parent Interaction after Term 1 Exam (Telephonic)",
            date: 'January',
            liveDate:'July',
             category: 'Music',
            subImage:[
                {
                    index:10,
                    image:item7,
                },
                {
                    index:11,
                    image:item8,
                },
                {
                    index:12,
                    image:item5,
                },
            ]
        }, 
        // {
        //     id:5,
        //     image: item5,
        //     subtext: '@BigBull',
        //     title: 'Shibuya Scramble Punks',
        //     avatar: image5,
        //     name:"Floyd Glasgow",
        //     liveDate:'July 19, 2024 6:0:0',
        //     category: 'Video',
        //     subImage:[
        //         {
        //             index:13,
        //             image:item15,
        //         },
        //         {
        //             index:14,
        //             image:item9,
        //         },
        //         {
        //             index:15,
        //             image:item12,
        //         },
        //     ]
        // }, {
        //     id:6,
        //     image: item6,
        //     subtext: '@Angel',
        //     title: 'Probably A Label #650',
        //     avatar: image6,
        //     name:"Donna Schultz",
        //     liveDate:'July 20, 2024 6:0:0',
        //     category: 'Games',
        //     subImage:[
        //         {
        //             index:16,
        //             image:item10,
        //         },
        //         {
        //             index:17,
        //             image:item3,
        //         },
        //         {
        //             index:18,
        //             image:item11,
        //         },
        //     ]
        // }, {
        //     id:7,
        //     image: item7,
        //     subtext: '@CrazyAnyone',
        //     title: 'Looki#0147',
        //     avatar: image7,
        //     name:"Joshua Morris",
        //     date: 'December 29, 2023 6:0:0',
        //     liveDate:'February 17, 2024 6:0:0',
        //     category: 'Games',
        //     subImage:[
        //         {
        //             index:19,
        //             image:item16,
        //         },
        //         {
        //             index:20,
        //             image:item17,
        //         },
        //         {
        //             index:21,
        //             image:item18,
        //         },
        //     ]
        // }, {
        //     id:8,
        //     image: item8,
        //     subtext: '@Princess',
        //     title: 'Poob #285',
        //     avatar: image8,
        //     name:"Rosaria Vargas",
        //     liveDate:'April 15, 2024 6:0:0',
        //     category: 'Art',
        //     subImage:[
        //         {
        //             index:22,
        //             image:item7,
        //         },
        //         {
        //             index:24,
        //             image:item9,
        //         },
        //         {
        //             index:24,
        //             image:item19,
        //         },
        //     ]
        // },
        // {
        //     id:9,
        //     image: item9,
        //     subtext: '@LooserBad',
        //     title: 'Umber Arrow',
        //     avatar: image9,
        //     name:"Carl Williams",
        //     liveDate:'May 17, 2024 6:0:0',
        //     category: 'Music',
        //     subImage:[
        //         {
        //             index:25,
        //             image:item22,
        //         },
        //         {
        //             index:26,
        //             image:item23,
        //         },
        //         {
        //             index:27,
        //             image:item24,
        //         },
        //     ]
        // },
        // {
        //     id:10,
        //     image: item10,
        //     subtext: '@Princess',
        //     title: 'Gloam Druid',
        //     avatar: image10,
        //     name:"Rosaria Vargas",
        //     liveDate:'August 8, 2024 6:0:0',
        //      category: 'Memes',
        //     subImage:[
        //         {
        //             index:28,
        //             image:item25,
        //         },
        //         {
        //             index:29,
        //             image:item19,
        //         },
        //         {
        //             index:30,
        //             image:item26,
        //         },
        //     ]
        // },
        // {
        //     id:11,
        //     image: item11,
        //     subtext: '@PandaOne',
        //     title: 'Azuki #7421',
        //     avatar: image11,
        //     name:"Julius Canale",
        //     liveDate:'December 27, 2024 6:0:0',
        //      category: 'Memes',
        //     subImage:[
        //         {
        //             index:31,
        //             image:item27,
        //         },
        //         {
        //             index:32,
        //             image:item24,
        //         },
        //         {
        //             index:33,
        //             image:item28,
        //         },
        //     ]
        // },
        // {
        //     id:12,
        //     image: item12,
        //     subtext: '@FunnyGuy',
        //     title: 'Wolf-Cult Vanguard',
        //     avatar: image12,
        //     name:"Michael Williams",
        //     liveDate:'December 25, 2024 6:0:0',
        //     category: 'Music',
        //     subImage:[
        //         {
        //             index:34,
        //             image:item7,
        //         },
        //         {
        //             index:35,
        //             image:item14,
        //         },
        //         {
        //             index:36,
        //             image:item19,
        //         },
        //     ]
        // },
]
export const tutionEntrance = [
    {
        id:1,
        title: 'NEET/IIT-JEE/KEAM Tuition + Entrance',
        p1:'Students completed class 10th(CBSE or State)',
        p2:'Two Years',
        p3:'Based on 10thmark & screening test',
        p4:'June',
        image: blog1,
        category: 'Arts',
        subtext: '@StreetBoy',
        date:"13th September,2023",
    },
    
]
export const tution = [
    {
        id:1,
        title: 'Plus One & Plus Two Tuition Daily Batch',
        p1:'Students completed class 10th(CBSE or State)',
        p2:'Two Years',
        p3:'Morning or Evening',
        image: blog1,
        category: 'Arts',
        subtext: '@StreetBoy',
        date:"13th September,2023",
    },
    {
        id:2,
        title: 'Plus One & Plus Two Tuition week end Batch',
        p1:'Students completed class 10th(CBSE or State)',
        p2:'Two Years',
        p3:'Morning or Evening',
        image: blog2,
        category: 'Illustration',
        subtext: '@CutieGirl',
        date:"29th November, 2023"
    },
    {
        id:3,
        title: '8th, 9th& 10thTuition Daily Batch',
        p1:'Students from 8th Standard to 10thStandard(CBSE or State)',
        p2:'One Year',
        p3:'Morning or Evening',
        image: blog3,
        category: 'Music',
        subtext: '@ButterFly',
        date: "29th December, 2023"
    },
]
export const vacation = [
    {
        id:1,
        title: 'Abacus',
        p1:'Students from 5thStandard to 8thStandard(CBSE or State)',
        p2:'Flexible',
        p3:'Vacation & holidays',
        image: blog1,
        category: 'Arts',
        subtext: '@StreetBoy',
        date:"13th September,2023",
    },
    {
        id:2,
        title: 'Robotics & Coding',
        p1:'Students from 5thStandard to 8thStandard(CBSE or State)',
        p2:'Flexible',
        p3:'Vacation & holidays',
        image: blog2,
        category: 'Illustration',
        subtext: '@CutieGirl',
        date:"29th November, 2023"
    },
    {
        id:3,
        title: 'AI(Artificial Intelligence) & Machine Learning',
        p1:'Students from 9th& 10thStandard Standard(CBSE or State)',
        p2:'Flexible',
        p3:'Vacation & holidays',
        image: blog3,
        category: 'Music',
        subtext: '@ButterFly',
        date: "29th December, 2023"
    },
    {
        id:4,
        title: 'Foundation Course for competitive Exams',
        p1:'Students from 8th Standard to 10thStandard(CBSE or State)',
        p2:'One Year',
        p3:'Vacation & holidays',
        image: blog4,
        category: 'Video',
        subtext: '@NorseQueen',
        date:'13th March, 2023',
    },
]
export const entrance = [
    {
        id:1,
        title: 'NEET/IIT-JEE/KEAM Holiday',
        image: blog1,
        p1:'Students completed class 10th(CBSE or State)',
        p2:'Two Years',
        p3:'Based on 10thmark & screening test',
        p4:'June',
        category: 'Arts',
        subtext: '@StreetBoy',
        date:"13th September,2023",
        title1:"Question Papers",
        q1:"Questions & Answers NEET 2019",
        q2:"Questions & Answers JEE 2019",
        q3:"Questions & Answers KEAM 2019",
    },
    {
        id:2,
        title: 'NEET/IIT-JEE/KEAM Entrance Crash Course',
        p1:'Students Studying 12th (CBSE or State)',
        p2:'One Month',
        p3:'Based on 10th, 11th marks & screening test',
        p4:'March',
        p5:'Dec to March',
        image: blog2,
        category: 'Illustration',
        subtext: '@CutieGirl',
        date:"29th November, 2023",
        title1:"Question Papers",
        q1:"Questions & Answers NEET 2019",
        q2:"Questions & Answers JEE 2019",
        q3:"Questions & Answers KEAM 2019",
    },
    {
        id:3,
        title: 'NEET/IIT-JEE/KEAM Repeater Batch',
        p1:'Students Studying 12th(CBSE or State)',
        p2:'One Year',
        p3:'Based on 12th mark & screening test',
        p4:'June',
        p5:'Jan to Aug',
        image: blog3,
        category: 'Music',
        subtext: '@ButterFly',
        date: "29th December, 2023",
        title1:"Question Papers",
        q1:"Questions & Answers NEET 2019",
        q2:"Questions & Answers JEE 2019",
        q3:"Questions & Answers KEAM 2019",
    },
    {
        id:4,
        title: 'KVPY, Olympiad, NTSE,IISER, Jawahar Novodaya Vidyalaya',
        p1:'Students of relevant classes(CBSE or State)',
        p2:'One Year',
        p3:'Based on corresponding classmark &screening test',
        p4:'June',
        p5:'Jan to Dec',
        image: blog4,
        category: 'Video',
        subtext: '@NorseQueen',
        date:'13th March, 2023',
        title1:"Question Papers",
        q1:"Questions & Answers NEET 2019",
        q2:"Questions & Answers JEE 2019",
        q3:"Questions & Answers KEAM 2019",
    },
]
export const images = [item13,item3,item4,item1,image12,item6, item14, item13,item5, item7, item8, item5, item15, item9, item12, item10, item3, item11, item16 ,item17, item18, item7, item9, item19, item22, item23, item24, item25, item19, item26, item27, item24, item28, item7, item14, item19  ]

export  const blogData = [
    {
        id:1,
        title: 'Mindfulness Activities for Kids & Toddlers with NFT',
        image: blog1,
        category: 'Arts',
        subtext: '@StreetBoy',
        date:"13th September,2023",
    },
    {
        id:2,
        title: 'Save Thousands Of Lives Through This NFT',
        image: blog2,
        category: 'Illustration',
        subtext: '@CutieGirl',
        date:"29th November, 2023"
    },
    {
        id:3,
        title: 'A place where technology meets craftsmanship',
        image: blog3,
        category: 'Music',
        subtext: '@ButterFly',
        date: "29th December, 2023"
    },
    {
        id:4,
        title: 'NFT Market - A Compact Trike with the Big Benefits',
        image: blog4,
        category: 'Video',
        subtext: '@NorseQueen',
        date:'13th March, 2023',
    },
    {
        id:5,
        title: 'Honoring Black History Month with Toddlers',
        image: blog5,
        category: 'Games',
        subtext: '@@BigBull',
        date:"6th June, 2023"
    },
    {
        id:6,
        title: 'Setting Intentions Instead of Resolutions for 2021',
        image: blog6,
        category: 'Memes',
        subtext: '@Angel',
        date:'19th July, 2023'
    },
    {
        id:7,
        title: 'Clever Ways to Purchase Extraordinart Items',
        image: blog7,
        category: 'GIFs',
        subtext: '@CrazyAnyone',
        date:"20th July, 2023"
    },
    {
        id:8,
        title: 'How to Save Money on Baby Essentials for NFT',
        image: blog8,
        category: 'Video',
        subtext: '@Princess',
        date:"31st August, 2023"
    },
    {
        id:9,
        title: 'Liki Trike - A Compact Trike with the Big Benefits',
        image: blog9,
        category: 'GIFs',
        subtext: '@CrazyAnyone',
        date:"13th September,2023",
    },
    {
        id:10,
        title: 'NFT Market - A Compact the Big Benefits',
        image: blog10,
        category: 'Tech',
        subtext: '@Princess',
        date:"29th November, 2023"
    },
    {
        id:11,
        title: 'Behind the Scenes of the creabik App',
        image: blog11,
        category: 'Arts',
        subtext: '@PandaOne',
        date:'13th March, 2023',
    },
    {
        id:12,
        title: 'Meet fennouni, Product Designer at GitHub',
        image: blog12,
        category: 'GIFs',
        subtext: '@FunnyGuy',
        date:'19th July, 2023'
    }
]
export  const EventImages = [
    {
        id:1,
        image: events1,
    },
    {
        id:2,
        image: events2,
    },
    {
        id:3,
        image: events3,
    },
    {
        id:4,
        image: events4,
    },
    
]
export  const ResultImages = [
    {
        id:1,
        img2021: Result1,
        img2022: Result26,
        img2023: Result31,
        img2024:image21,
    },
    {
        id:2,
        img2021: Result2,
        img2022: Result27,
        img2023: Result32,
        img2024: image22,
    },
    {
        id:3,
        img2021: Result3,
        img2022: Result28,
        img2024:image23,
    },
    {
        id:4,
        img2021: Result4,
        img2022: Result29,
        img2024:image24,
    },
    {
        id:5,
        img2021: Result5,
        img2024: Result34,
    },
    {
        id:6,
        img2021: Result6,
        img2024: Result33,
    },
    {
        id:7,
        img2021: Result7,
        img2024: Result35,
    },
    {
        id:8,
        img2021: Result8,
    },
    {
        id:9,
        img2021: Result9,
    },
    {
        id:10,
        img2021: Result10,
    },
    {
        id:11,
        img2021: Result11,
    },
    {
        id:12,
        img2021: Result12,
    },
    {
        id:13,
        img2021: Result13,
    },
    {
        id:14,
        img2021: Result14,
    },
    {
        id:15,
        img2021: Result15,
    },
    {
        id:16,
        img2021: Result16,
    },
    {
        id:17,
        img2021: Result17,
    },
    {
        id:18,
        img2021: Result18,
    },
    {
        id:19,
        img2021: Result19,
    },
    {
        id:20,
        img2021: Result20,
    },
    {
        id:21,
        img2021: Result21,
    },
    {
        id:22,
        img2021: Result22,
    },
    {
        id:23,
        img2021: Result23,
    },
    {
        id:24,
        img2021: Result24,
    },
    {
        id:25,
        img2021: Result25,
    },
    
]

export const studentFeedbacks = [
    {
        id:1,
        img: feedback3,
        videoLink:"grYiOEjE_kQ",
    },
    {
        id:2,
        img: feedback2,
        videoLink:"gxjd_0Nxjt4",
    },
    {
        id:3,
        img: feedback1,
        videoLink:"uuPVAfePWSE",
    },
    {
        id:4,
        img:KBS,
        videoLink:"D_VvFVPUAAw",
    },
    ]