import React, { useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";
import { blogData } from "../../data/data";

export default function KbsHomeFaculty() {
  const params = useParams();
  const id = params.id;
  const blog = blogData.find((blogs) => blogs.id === parseInt(id));

  const [counters, setCounters] = useState([
    {
      id: 1,
      target: 7000,
      current: 0,
      label: "STUDENTS ENROLLED​",
    },
    {
      id: 2,
      target: 10000,
      current: 0,
      label: "POWERFUL MCQ Q-BANK",
    },
    {
      id: 3,
      target: 800,
      current: 0,
      label: "HOURS OF RECORDED LESSONS",
    },
    {
      id: 4,
      target: 6000,
      current: 0,
      label: "VERIFIABLE REVIEWS",
    },
  ]);

  useEffect(() => {
    const updateCounter = (index) => {
      setCounters((prevCounters) => {
        const newCounters = [...prevCounters];
        const increment = newCounters[index].target / 2000000000;
        if (newCounters[index].current < newCounters[index].target) {
          newCounters[index].current = Math.ceil(
            newCounters[index].current + increment
          );
          setTimeout(() => updateCounter(index), 1);
        } else {
          newCounters[index].current = newCounters[index].target;
        }
        return newCounters;
      });
    };

    counters.forEach((_, index) => updateCounter(index));
  }, []);

  return (
    <>
      {/* <nav className="custom-nav">
              <ul>
                <li className="facebook">
                  <a href="#">
                    <i className="bi--facebook"></i>
                    <span>Facebook</span>
                  </a>
                </li>
                <li className="twitter">
                  <a href="#">
                    <i className="bi--twitter"></i>
                    <span>Twitter</span>
                  </a>
                </li>
                <li className="instagram">
                  <a href="#">
                    <i className="lucide--instagram"></i>
                    <span>Instagram</span>
                  </a>
                </li>
                <li className="youtube">
                  <a href="#">
                    <i className="ri--youtube-fill "></i>
                    <span>Youtube</span>
                  </a>
                </li>
              </ul>
            </nav> */}
      <section className="relative pt-32 md:pb-24 pb-16">
        <div className="container">
          <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
            {counters.map((counter, index) => (
              <div key={index} className="counter-container">
                <div className="counter">{counter.current}+</div>

                <span
                  style={{
                    marginTop: "20px",
                    fontSize: "20px",
                    fontFamily: "bold",
                  }}
                >
                  {counter.label}
                </span>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
